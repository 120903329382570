import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MyBankCard from "../Components/MyBank/MyBankCard";
import ProductsFilter from "../Components/products-filter";
import axios from "axios";
import MyBankCreateOrder from "../Components/MyBank/MyBankCreateOrder";
import SendMoney from "../Present-Or-Money/send-money";
import SinglePurchase from "../Components/purchase-single";
import NotEnoughMoneyPopup from "../Popups/NotEnoughMoneyPopup";
import { API_DOMAIN } from "../Libs/config";
import MyBankSinglePurchase from "../Components/MyBank/MyBankSinglePurchase";
import { SwiperSlide, Swiper } from "swiper/react";
import arrowLeftBlack from "../Assets/img/svg/arrow-left-black.svg";
import ProductPage from './single-product';
import MyBankAllCategories from "./my-bank-all-categories";
import MyBankCategory from "../Components/MyBank/MyBankCategory";
import MyBankProducts from "./mybank-products";


import {
  homeBg,
  homeBgNoPeople,
  orangeBgImage,
  orangeBgImageNoPeople,
  present1,
  topbarBgGreen,
  topbarBg,
  giftBasketTopbar,
} from "../Assets/images";
import MyBankProduct from "../Components/MyBank/MyBankProduct";
import { useSyncExternalStore } from "react";
import ProductPlaceholder from "../Components/ProductPlaceholder";
import MyBankCreateOrder1 from "../Components/MyBank/MyBankCreateOrder1";

const currEntWishlistObg = {
  result: true,
  whishlistItemsList: [
    {
      id: 632,
      wishlistId: 138,
      productsId: 317,
      productName: "הרמס וויאג (א) אדט 100מל",
      providerId: "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      providerName: "וסילי גרידנב",
      status: "not buy",
      buyerId: null,
      ownerId: "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      productImage: null,
    },
    {
      id: 637,
      wishlistId: 138,
      productsId: 1,
      productName: "קוקו שאנל מאדמוזל דאו ספריי (א) 100מל",
      providerId: "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      providerName: "וסילי גרידנב",
      status: "buy by me",
      buyerId: "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      ownerId: "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      productImage: null,
    },
    {
      id: 638,
      wishlistId: 138,
      productsId: 3,
      productName: "גיבנשי לינטרדיט רוז (א) אדפ 50מל",
      providerId: "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      providerName: "וסילי גרידנב",
      status: "not buy",
      buyerId: null,
      ownerId: "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      productImage: null,
    },
    {
      id: 658,
      wishlistId: 138,
      productsId: 2,
      productName: "נינה ריצי לאקסטז (א) אדפ 80מל",
      providerId: "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      providerName: "וסילי גרידנב",
      status: "not buy",
      buyerId: null,
      ownerId: "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      productImage: null,
    },
  ],
  whishlistObject: {
    id: 138,
    userId: "642793b5-beb8-4ede-acce-0e74fd4f47f5",
    creationDate: "2023-08-17T13:49:35.7436332",
    eventDate: "2023-09-08T11:58:00",
    name: "חינה 7777",
    description: "חינה 7777",
    buyerIdList: null,
    isActive: false,
    houseNumber: "2",
    apartmentNumber: "2",
    entranceNumber: "2",
    mailbox: "",
    postal: "77777777",
    arrivalTime: "11:58",
    contactPhone: "0585444533",
    type: "Henna",
    city: "אבו ג'ווייעד (שבט)",
    street: "אבו ג'ווייעד (שבט)",
    isRemovable: false,
  },
  whishlistItemsDetailsList: [
    {
      wishlistId: 138,
      wishListItemId: 632,
      status: "not buy",
      productsId: 317,
      productName: "הרמס וויאג (א) אדט 100מל",
      providerId: "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      providerName: "וסילי גרידנב",
      image: "https://morneta.co.il/wp-content/uploads/2022/11/50038286.jpg",
      price: 1528.0,
      date: "0001-01-01T00:00:00",
      isRemovable: true,
    },
    {
      wishlistId: 138,
      wishListItemId: 637,
      status: "buy by me",
      productsId: 1,
      productName: "קוקו שאנל מאדמוזל דאו ספריי (א) 100מל",
      providerId: "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      providerName: "וסילי גרידנב",
      image: "https://morneta.co.il/wp-content/uploads/2023/01/13299312.jpg",
      price: 243.0,
      date: "0001-01-01T00:00:00",
      isRemovable: false,
    },
    {
      wishlistId: 138,
      wishListItemId: 638,
      status: "not buy",
      productsId: 3,
      productName: "גיבנשי לינטרדיט רוז (א) אדפ 50מל",
      providerId: "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      providerName: "וסילי גרידנב",
      image: "https://morneta.co.il/wp-content/uploads/2022/11/13222140.jpg",
      price: 255.0,
      date: "0001-01-01T00:00:00",
      isRemovable: true,
    },
    {
      wishlistId: 138,
      wishListItemId: 658,
      status: "not buy",
      productsId: 2,
      productName: "נינה ריצי לאקסטז (א) אדפ 80מל",
      providerId: "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      providerName: "וסילי גרידנב",
      image: "https://morneta.co.il/wp-content/uploads/2022/12/admin-ajax.jpg",
      price: 169.0,
      date: "0001-01-01T00:00:00",
      isRemovable: true,
    },
  ],
  whishlistUser: {
    rawPass: "123123",
    firstName: "Vasily",
    lastName: "Gridnev",
    companyName: "",
    bankAccountId: "",
    isEnable: true,
    dob: "2023-08-03T08:51:32.0173766",
    otpCode: "72737",
    otpDate: "2023-08-16T16:09:20.8873683",
    birthdate: "2023-07-31T00:00:00",
    mailBox: null,
    postal: "",
    houseNumber: "3",
    apartmentNumber: "",
    entranceNumber: "",
    email: "brand@brand.com",
    city: "אילת",
    street: "רות המואביה",
    id: "642793b5-beb8-4ede-acce-0e74fd4f47f5",
    userName: "0585444533",
    normalizedUserName: "0585444533",
    normalizedEmail: null,
    emailConfirmed: true,
    passwordHash:
      "AQAAAAEAACcQAAAAEGaFasN/Y+ivL5foVCf34kmlaaNfv9PbdDdgkTqe31/yQo7OOf6lJnr/D99ekMJQQQ==",
    securityStamp: "AHWKIVRRKY7DWZ5SBTC32K2JT7NULNWN",
    concurrencyStamp: "37f48cca-f64d-42e9-9623-e23cc56327b0",
    phoneNumber: "0585444533",
    phoneNumberConfirmed: false,
    twoFactorEnabled: false,
    lockoutEnd: null,
    lockoutEnabled: true,
    accessFailedCount: 0,
  },
  isWishlistRemovable: false,
};

export default function MyBank({
  allCategoriesList,
  filterToggle,
  setMyEventsActiveTab,
  setFilterObject,
  filterObject,
  currUserObject,
  updatePopupState,
  addItemHandler,
  openImagePopup,
  currWishlist,
  setCurrWishlist,
  languageToggle,
  language,
  isFilterOpen,
  setCurrUserObject,
  setAllCategoriesList,

  setShowCategMenuItem,
  eventType,
  setEventType,
  setProductsFilterObject,
  productsFilterObject

}) {
  const location = useLocation();
  const navigate = useNavigate();
  const purchasedState = location.state?.purchased || false;
  console.log("purchasedState", purchasedState);
  const [sortFilter, setSortFilter] = useState({
    isOpen: false,
    filter: "date",
    sortType: "asc",
  });
  const [cartType, setCartType] = useState("purchased");
  const [activeTab, setActiveTab] = useState(0);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [nextIndex, setNextIndex] = useState(0);
  const [presentType, setPresentType] = useState(null);
  const [isOrange, setOrange] = useState(true);
  const [isGreenMobile, setGreenMobile] = useState(true);
  const [choosedPresent, setChoosedPresent] = useState({});
  const [isChoosed, setChoosed] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [isForm, setForm] = useState(true);
  const [resultObject, setResultObject] = useState({});
  const [wishListInfo, setWishListInfo] = useState(null);
  const [mainBgImage, setMainBgImage] = useState({
    main: "",
    noPeople: "",
  });

  const [rangePriceMinMax, setRangePriceMinMax] = useState({
    min: null,
    max: null,
  });
  const [progress, setProgress] = useState(0);
  const [sendChoosedPresentStep, setSendChoosedPresentStep] = useState({
    blessingWrite: false,
    shipping: false,
    terms: false,
    moneyPresent: false,
    payment: false,
    successfulPayment: false,
  });
  const [sendMoneyStep, setMoneyStep] = useState({
    howMuchForPresent: false,
    blessingWrite: false,
    moneyPresent: false,
    payment: false,
    successfulPayment: false,
  });

  const [myBank, setMyBank] = useState(0);
  const [userData, setUserData] = useState({});
  const [notEnoughMoneyPopup, setNotEnoughMoneyPopup] = useState(false);
  const [boughtForBankMoney, setBoughtForBankMoney] = useState([]);

  const [generalBought, setGeneralBought] = useState([]);

  const [activeHeroSection, setActiveHeroSection] = useState(2);

  // sliders state
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [isMobile, setIsMobile] = useState(false);
  const [relatedPrevActive, setRelatedPrevActive] = useState(true);
  const [recentPrevActive, setRecentPrevActive] = useState(true);
  const [activeIndexRecent, setActiveIndexRecent] = useState(0);
  const [isClicked, setIsClicked] = useState(false);

  const mainSwiperRef = useRef(null);
  const thumbnailSwiperRef = useRef(null);
  const mainProductsRelated = useRef(null);
  const mainProductsRecent = useRef(null);

  const handleThumbnailClick = (index) => {
    const swiperInstance = mainSwiperRef.current?.swiper;
    // const currentIndex = swiperInstance?.realIndex;
    // const lastIndex = swiperInstance.slides.length - 1;

    if (mainSwiperRef?.current && mainSwiperRef.current?.swiper) {
      swiperInstance.slideTo(index);
    }
  };
  const goToCartByWishlist1 = () => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=3d926775-bfba-4b43-8099-32ba025c7ea3`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate(`/cart/${whishLists[whishLists.length - 1].id}`);
        } else {
          //setLoading(false);
          //alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

  };

  const handleSlideStep = (swiper, direction) => {
    if (swiper && swiper.slideTo) {
      const currentIndex = swiper?.realIndex;
      const lastIndex = swiper?.slides.length - 1;
      console.log("handleSlideStep: ", currentIndex, lastIndex);

      let newIndex;
      if (direction === "prev") {
        newIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
      } else if (direction === "next") {
        newIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
      }

      if (newIndex !== undefined) {
        swiper.slideTo(newIndex);
      }
    }
  };
  const toggleButton = () => {
    setIsClicked(!isClicked);
    console.log("ffffffffffff ", isClicked)
  };
  useEffect(() => {
    // console.log(mainProductsRelated.current?.swiper?.realIndex == 0, 'mainProductsRelated.current?.swiper.realIndex ')
    if (mainProductsRelated.current?.swiper?.realIndex == 0) {
      setRelatedPrevActive(true);
    } else {
      setRelatedPrevActive(false);
    }
  }, [mainProductsRelated.current?.swiper?.realIndex]);

  useEffect(() => {

    if (myBank > 0)
      setActiveHeroSection(1)
    else
      setActiveHeroSection(2);
    console.log(activeHeroSection);
  }, []);

  useEffect(() => {
    // console.log(mainProductsRecent.current?.swiper?.realIndex == 0, 'mainProductsRecent.current?.swiper.realIndex ')
    if (mainProductsRecent.current?.swiper?.realIndex == 0) {
      setRecentPrevActive(true);
    } else {
      setRecentPrevActive(false);
    }
  }, [mainProductsRecent.current?.swiper?.realIndex]);

  // returns my-bank page view to categories, closing MakeOrder flow with the present and opens purchased presents with the new one just bought
  const resetMyBankOrderFlow = () => {
    setChoosed("");
    setActiveTab(1);
  };

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
    console.log(
      "location_my_bank_debug",
      location,
      location.state,
      location.state?.purchased,
    );
    // if /my-bank is opened from success popup after buying a product, we show second tab as active (purchased) products
    setActiveTab(location.state?.purchased ? 1 : 0);
  }, []);
  const handleButtonClick = () => {
    // Navigate to the specified route
    navigate(`all-categories/?wishlistId=${13}`);
  };

  const handleSortDrop = () => {
    const newValue = !sortFilter.isOpen;
    setSortFilter({
      isOpen: newValue,
      filter: sortFilter.filter,
      sortType: sortFilter.sortType,
    });
  };

  const setFilterPrice = () => {
    setSortFilter({
      isOpen: false,
      filter: "price",
      sortType: sortFilter.sortType,
    });
  };

  const setFilterDate = () => {
    setSortFilter({
      isOpen: false,
      filter: "date",
      sortType: sortFilter.sortType,
    });
  };

  const setFilterStatus = () => {
    setSortFilter({
      isOpen: false,
      filter: "status",
      sortType: sortFilter.sortType,
    });
  };

  const handleCardClick = (e, id) => {
    const { target } = e;
    console.log(target);

    if (
      target.closest(".single-products-card__link.btn") ||
      target.closest(".single-products-card__text-content")
    )
      return;
    // Handle card click to open one root
    navigate(`/single-product/${id}`);
  };

  const goToChoosedPresentForm = (present) => {
    // console.log('goToChoosedPresentForm', present)

    if (present?.price > myBank) {
      updatePopupState(true);
      setNotEnoughMoneyPopup(true);
      return;
    }

    const currUserObject = JSON.parse(localStorage.getItem("currUserObject"));
    const {
      user: { id },
    } = currUserObject;
    setPresentType("present");
    setChoosed(true);
    setStepNumber(1);
    setProgress(5);
    setMainBgImage({
      main: homeBg,
      noPeople: homeBgNoPeople,
    });
    setSendChoosedPresentStep({
      blessingWrite: true,
      shipping: false,
    });
    // console.log('goToChoosedPresentForm: present', present);
    setChoosedPresent(present);
    setResultObject((prevObj) => {
      const newObj = {
        ...prevObj,
        // wishlistItemsId: [present?.id],
        presentType: "present",
        buyerId: userData?.user?.id,
        // buyerId: currUserObject.user?.id,
        // ownerId: wishListInfo?.whishlistUser??.id,
        ownerId: id,
        productId: present?.id,
      };
      return newObj;
    });
    navigate("buy/write-blessing");
    window.scrollTo(0, 0);
  };

  const handleFilterChecks = (
    e,
    choosedCategory = null,
    manufacturer = null,
  ) => {
    // console.log('handleFilterChecks:', e.target, choosedCategory, manufacturer)

    if (e.target?.id.includes("category-check")) {
      if (e.target.checked) {
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            choosedCategoriesList: [
              ...prevObj.choosedCategoriesList,
              choosedCategory,
            ],
            lastChangedType: "category",
          };
          return newObj;
        });

        const headerCheck = document.getElementById(
          `header-category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`,
        );
        if (headerCheck) {
          headerCheck.checked = true;
        }
      } else {
        let newCategoriesList = [...filterObject.choosedCategoriesList];
        filterObject.choosedCategoriesList.filter((item) => {
          if (
            item.categoryId === choosedCategory.categoryId &&
            item.productsId === choosedCategory.productsId
          ) {
            newCategoriesList.splice(newCategoriesList.indexOf(item), 1);
          }
          return newCategoriesList;
        });

        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            choosedCategoriesList: [...newCategoriesList],
            lastChangedType: "category",
          };
          return newObj;
        });

        // getProductsByCategoriesList(prevCategoriesList);
        // setChoosedCategoriesList(prevCategoriesList);
        const headerCheck = document.getElementById(
          `header-category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`,
        );
        if (headerCheck) {
          headerCheck.checked = false;
        }
      }
    } else if (e.target?.id.includes("manufacturer_")) {
      if (e.target.checked) {
        setFilterObject((prevObj) => {
          if (!prevObj.manufacturersList.includes(manufacturer)) {
            // Convert the manufacturersList to a Set to remove duplicates
            const uniqueManufacturers = new Set(prevObj.manufacturersList);

            // Add the new manufacturer to the Set
            uniqueManufacturers.add(manufacturer);

            // Convert the Set back to an array
            const updatedManufacturersList = [...uniqueManufacturers];

            const newObj = {
              ...prevObj,
              manufacturersList: updatedManufacturersList,
              priceRange: null,
              lastChangedType: "manufacturer",
            };
            return newObj;
          }
          return prevObj;
        });
      } else {
        setFilterObject((prevObj) => {
          let newArr = [...prevObj.manufacturersList];
          newArr = newArr.filter((item) => item !== manufacturer);
          // console.log(newArr);
          const newObj = {
            ...prevObj,
            manufacturersList: newArr,
            priceRange: null,
            lastChangedType: "manufacturer",
          };
          return newObj;
        });
      }
    }
  };

  const handleFilterProducts = (array) => {
    setProducts(() => {
      const newArr = array.filter((product) => {
        if (!filterObject.manufacturersList.length) {
          if (!filterObject.priceRange) {
            return productsAll;
          } else {
            return (
              product.price >= filterObject.priceRange[0] &&
              product.price <= filterObject.priceRange[1]
            );
          }
        }
        if (!filterObject.priceRange) {
          return filterObject.manufacturersList.includes(product.manufacturer);
        } else {
          return (
            filterObject.manufacturersList.includes(product.manufacturer) &&
            product.price >= filterObject.priceRange[0] &&
            product.price <= filterObject.priceRange[1]
          );
        }
      });

      return newArr;
    });
  };

  function minMaxPriceHandler(products) {
    const pricesArr = products.map((product) => Number(product.price));
    const pricesSorted = pricesArr.sort((a, b) => a - b);
    // console.log(pricesSorted, 'priceArr')

    // const min = floorToTenThousands(pricesSorted[0]);
    // const max = ceilToHundredThousands(pricesSorted[pricesSorted.length - 1]);
    const min = pricesSorted[0];
    const max = pricesSorted[pricesSorted.length - 1];

    return { minPrice: min, maxPrice: max };
  }

  const getProductsByCategoriesList = (idList) => {
    if (idList.length) {
      setProducts([]);
      setProductsAll([]);
      let finalArray = [];
      let i = 0;
      idList.forEach((item) => {
        axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/GetProductsByCategoryId?categoryId=${item.productsId}`,
          )
          .then((response) => {
            // console.log(response.data.result.products);
            const allProducts = response.data.result.products;
            finalArray = [...finalArray, ...allProducts];

            i++;
            if (i === idList?.length) {
              // console.log(finalArray);
              setProducts(finalArray);
              setProductsAll(finalArray);
            }
          })
          .catch((error) => {
            console.error(error);
            i++;
            alert("Error, please try again");
          });
      });
    }
  };

  // Creating order with the gift money that are saved in myBank variable.

  const getPurchasedProductsWithBankMoney = async () => {
    try {
      const currUserObject = JSON.parse(localStorage.getItem("currUserObject"));
      const {
        user: { id },
      } = currUserObject;
      // const params = { userId: id }
      const response = await axios.post(
        `${API_DOMAIN}/api/ProductCategory/getMyBankOrders?userId=${id}`,
      );
      if (response.status === 200) {
        // console.log('getPurchasedProductsWithBankMoney: ', response.data)
        setBoughtForBankMoney(response.data);
        console.log("kkkkkkk",response.data)
      }
    } catch (error) {
      console.log(error);
     // alert("Something went wrong", error);
    }
  };
  const   GetMyGeneralpurchases  = async () => {
    try {
      const currUserObject = JSON.parse(localStorage.getItem("currUserObject"));
      const {
        user: { id },
      } = currUserObject;
      // const params = { userId: id }
      const response = await axios.post(
        `${API_DOMAIN}/api/ProductCategory/getMyGeneralpurchases?userId=${id}`,
      );
      if (response.status === 200) {
        // console.log('getPurchasedProductsWithBankMoney: ', response.data)
        setGeneralBought(response.data);
        console.log("kkkkkkk",response.data)
      }
    } catch (error) {
      console.log(error);
     // alert("Something went wrong", error);
    }
  };
  const getUserData = async () => {
    try {
      const currUserObject = JSON.parse(localStorage.getItem("currUserObject"));

      // Check if currUserObject is not null
      if (currUserObject) {
        const { user } = currUserObject;

        // Check if user is not null
        if (user) {
          const { id } = user;

          const response = await axios.get(
            `${API_DOMAIN}/api/Authenticate/getUserData?userId=${id}`
          );

          if (response.status === 200) {
            const {
              data: {
                user: { myBank },
              },
            } = response;

            setUserData(response.data);
            setMyBank(myBank ? myBank : 0);
          } else {
            console.error("Unexpected response status:", response.status);
           // alert("Something went wrong");
          }
        } else {
          console.error("Invalid user object: user is null");
          // Handle the case where user is null
        }
      } else {
        console.error("currUserObject is null");
        // Handle the case where currUserObject is null
      }
    } catch (error) {
      console.error(error);
     // alert("Something went wrong");
    }
  };



  useEffect(() => {
    getUserData();
    getPurchasedProductsWithBankMoney();
    GetMyGeneralpurchases();
    return () => { };
  }, []);

  const updateUserBank = async () => {
    getUserData();
    getPurchasedProductsWithBankMoney()
  }

  useEffect(() => {
    // console.log('filterObject:', filterObject);
    if (filterObject.lastChangedType === "category") {
      getProductsByCategoriesList(filterObject.choosedCategoriesList);
      setFilterObject((prevObj) => {
        const newObj = { ...prevObj, manufacturers: [], lastChangedType: "" };
        return newObj;
      });
      document.querySelectorAll(".manufacturer-check").forEach((item) => {
        if (item.checked) item.checked = false;
      });
    } else if (filterObject.lastChangedType !== "") {
      handleFilterProducts(productsAll);
    }
  }, [filterObject]);

  useEffect(() => {
    let finalArray = [];

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetProductsByCategoryId?categoryId=${6}`,
      )
      .then((response) => {
        // console.log(response.data.result.products);
        const allProducts = response.data.result.products;
        finalArray = [...finalArray, ...allProducts];

        // console.log(finalArray);
        setProducts(finalArray);
        setProductsAll(finalArray);
      })
      .catch((error) => {
        console.error(error);
        alert("Error, please try again");
      });

    return () => { };
  }, []);

  useEffect(() => {
    // console.log('myBankProducts', products);
    if (products.length) {
      const initialProducts = products.slice(0, 20);
      setVisibleProducts(initialProducts);
      setNextIndex(20);

      if (filterObject.lastChangedType !== "price") {
        const { minPrice, maxPrice } = minMaxPriceHandler(products);
        // console.log(minPrice, " ", maxPrice);
        setRangePriceMinMax({ min: minPrice, max: maxPrice });
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            priceRange: [minPrice, maxPrice],
            lastChangedType: "",
          };
          return newObj;
        });
      }
    }
  }, [products]);

  useEffect(() => {
    if (productsAll.length) {
      const manufacturersArr = productsAll.reduce((result, current) => {
        const { manufacturer } = current;
        const isDuplicate = result.some(
          (resultManufacturer) => resultManufacturer === manufacturer,
        );

        if (!isDuplicate && manufacturer !== "") {
          result.push(manufacturer);
        }

        return result;
      }, []);

      setManufacturers(manufacturersArr);
    }
  }, [productsAll]);

  useEffect(() => {
    // console.log(manufacturers);
  }, [manufacturers]);

  useEffect(() => {
    //return;
    const sortType = sortFilter.sortType;
    const currentActiveTab = cartType;
    const sortBy = sortFilter.filter;
    // console.log(sortFilter);

    setCurrWishlist((prevObj) => {
      const newObj = prevObj;
      const prevArr = prevObj.whishlistItemsDetailsList;
      let filteredArr = [];
      if (prevArr && prevArr.length) {
        filteredArr = prevArr;

        filteredArr.sort((a, b) => {
          const aValue = String(a[sortBy]);
          const bValue = String(b[sortBy]);
          if (sortType === "asc") {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        });
        // console.log(filteredArr);
      }

      // newObj.whishlistItemsDetailsList = filteredArr;
      return { ...newObj, whishlistItemsDetailsList: filteredArr };
    });
  }, [sortFilter]);

  useEffect(() => {
    setCurrWishlist(currEntWishlistObg);

    return () => { };
  }, []);
  useEffect(() => {
    console.log("activeHeroSection ", activeHeroSection);
    console.log("activeTab ", activeTab);


  }, [activeHeroSection, activeTab]);

  useEffect(() => {
    console.log(visibleProducts);
  }, [visibleProducts]);

  console.log(window.history, "window.history");

  return (
    <>
      <Routes>
        <Route
          path=""
          element={

            <>
              <main
                className="my-bank"
                data-fullscreen
              >
                <section className="my-bank__topbar topbar">
                  <div className="topbar__bg-img-ibg">
                    <img
                      src="img/common/topbar-bg-green.png"
                      alt="topbar"
                    />
                  </div>
                  <div className="topbar__container">
                    <div className="topbar__title-wrapper">
                      <h1 className="topbar__title">
                        הקופה שלי
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="156.143"
                            height="156.143"
                            viewBox="0 0 156.143 156.143"
                          >
                            <g
                              id="a"
                              transform="translate(-51.41 -86.97)"
                            >
                              <g
                                id="b"
                                transform="translate(52.91 88.559)"
                              >
                                <circle
                                  id="Эллипс_423"
                                  data-name="Эллипс 423"
                                  cx="49.772"
                                  cy="49.772"
                                  r="49.772"
                                  transform="translate(26.8 26.711)"
                                  fill="#fffcef"
                                  opacity="0.1"
                                />
                                <circle
                                  id="Эллипс_424"
                                  data-name="Эллипс 424"
                                  cx="9.189"
                                  cy="9.189"
                                  r="9.189"
                                  transform="translate(13.904 120.773)"
                                  fill="#3c9e1c"
                                  opacity="0.7"
                                />
                                <circle
                                  id="Эллипс_425"
                                  data-name="Эллипс 425"
                                  cx="15.314"
                                  cy="15.314"
                                  r="15.314"
                                  transform="translate(114.736 7.69)"
                                  fill="#fff"
                                  opacity="0.4"
                                />
                                <circle
                                  id="Эллипс_426"
                                  data-name="Эллипс 426"
                                  cx="76.5"
                                  cy="76.5"
                                  r="76.5"
                                  transform="translate(0 0.054)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeMiterlimit="10"
                                  strokeWidth="3"
                                  strokeDasharray="5 5"
                                />
                              </g>
                              <g
                                id="Сгруппировать_1514"
                                data-name="Сгруппировать 1514"
                                transform="translate(52.91 88.47)"
                              >
                                <path
                                  id="Контур_38461"
                                  data-name="Контур 38461"
                                  d="M206.053,165.042a76.542,76.542,0,1,1-11.445-40.291,76.57,76.57,0,0,1,11.445,40.291h0Z"
                                  transform="translate(-52.91 -88.47)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeMiterlimit="10"
                                  strokeWidth="3"
                                  strokeDasharray="0 20"
                                  fillRule="evenodd"
                                  opacity="0"
                                />
                                <line
                                  id="Линия_534"
                                  data-name="Линия 534"
                                  y2="55.91"
                                  transform="translate(33.112 51.048)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38462"
                                  data-name="Контур 38462"
                                  d="M215.95,490.488h74.4a4.862,4.862,0,0,0,4.862-4.862V471.04"
                                  transform="translate(-175.548 -376.237)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="1905"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 710 60 197.5 0"
                                />
                                <path
                                  id="Контур_38463"
                                  data-name="Контур 38463"
                                  d="M295.213,343.348V328.762a4.862,4.862,0,0,0-4.862-4.862h-74.4"
                                  transform="translate(-175.548 -265.559)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38464"
                                  data-name="Контур 38464"
                                  d="M294.044,300.549v-1.217a4.865,4.865,0,0,0-4.862-4.862H220.86"
                                  transform="translate(-179.241 -243.422)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="950"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 877.5 40 0 60"
                                />
                                <path
                                  id="Контур_38465"
                                  data-name="Контур 38465"
                                  d="M285.491,271.119V269.9a4.865,4.865,0,0,0-4.862-4.862H215.95"
                                  transform="translate(-175.548 -221.285)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="1077.5"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 78 467 0 560"
                                />
                                <path
                                  id="Контур_38466"
                                  data-name="Контур 38466"
                                  d="M484.157,384.974H472a4.861,4.861,0,0,0-4.862,4.862v7.293A4.861,4.861,0,0,0,472,401.991h12.137A4.861,4.861,0,0,0,489,397.112c-.022-6.686-.062-17.872-.062-17.872"
                                  transform="translate(-364.492 -307.186)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="497.5"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 438.76 40 0 60"
                                />
                                <path
                                  id="Контур_38467"
                                  data-name="Контур 38467"
                                  d="M193.813,527.383a7.3,7.3,0,0,1-7.293-7.293"
                                  transform="translate(-153.411 -413.133)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38468"
                                  data-name="Контур 38468"
                                  d="M193.813,279.626a7.293,7.293,0,1,1,0-14.586"
                                  transform="translate(-153.411 -221.285)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38469"
                                  data-name="Контур 38469"
                                  d="M540.672,382.76a4.864,4.864,0,0,1-4.862,4.862"
                                  transform="translate(-416.145 -309.834)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38470"
                                  data-name="Контур 38470"
                                  d="M540.672,368a4.864,4.864,0,0,0-4.862-4.862"
                                  transform="translate(-416.145 -295.076)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38471"
                                  data-name="Контур 38471"
                                  d="M491.861,426.9a2.431,2.431,0,1,1-2.431,2.431,2.43,2.43,0,0,1,2.431-2.431Z"
                                  transform="translate(-381.258 -343.036)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                />
                                <path
                                  id="Контур_38472"
                                  data-name="Контур 38472"
                                  d="M282.546,217.331l-13.267-22.981a4.859,4.859,0,0,0-6.639-1.779L219.68,217.331"
                                  transform="translate(-178.061 -174.79)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDashoffset="851.26"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 716.76 134.76 0 40"
                                />
                                <line
                                  id="Линия_535"
                                  data-name="Линия 535"
                                  x2="46.186"
                                  transform="translate(53.479 104.526)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="3"
                                  strokeDasharray="0 0 0 0 0 40 628.76 560"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </h1>
                    </div>
                  </div>
                </section>

                <div className="my-bank__breadcrumbs breadcrumbs">
                  <div className="breadcrumbs__container">
                    <ul className="breadcrumbs__list">
                      <li className="breadcrumbs__item">
                        <NavLink to="/">דף הבית</NavLink>
                      </li>
                      <li className="breadcrumbs__item">
                        <p>הקופה שלי</p>
                      </li>
                    </ul>
                  </div>
                </div>

                <section
                  className={`my-bank__main main-products main-my-bank ${activeTab == 1 ? "mb-0" : ""
                    }`}
                >
                  <div className="main-products__container main-my-bank__container desktop">
                    <div className="main-choose-event__navigation my-bank">
                      <ul className="main-choose-event__nav-list">
                        <li className="main-choose-event__nav-item">

                          <button
                            onClick={() => {
                              setActiveTab(0);
                            }}
                            type="button"

                            className={`main-choose-event__button ${activeTab === 0 ? "active" : ""
                              }`}
                          >
                            הקופה שלי
                          </button>
                        </li>
                        <li className="main-choose-event__nav-item">
                          <button
                            onClick={() => setActiveTab(1)}
                            type="button"
                            className={`main-choose-event__button ${activeTab === 1 ? "active" : ""
                              }`}
                          >
                          הרכישות שלי מכסף שקבלתי
                          </button>
                        </li>


                        <li className="main-choose-event__nav-item">
                          <button
                            onClick={() => setActiveTab(2)}
                            type="button"
                            className={`main-choose-event__button ${activeTab ===2 ? "active" : ""
                              }`}
                          >
                          הרכישות שלי
                          </button>
                        </li>
                      </ul>
                    </div>

                    {activeTab === 0 && (
                      <>

                        {/*<div className="main-products__filter-btn-container filter-btn-container">
                          <button
                            type="button"
                            className="filter-btn"
                            onClick={filterToggle}
                          >
                            <img
                              src="img/svg/filter-icon.png"
                              alt="filter icon"
                            />
                          </button>
                        </div>*/}
                        <h2 className="main-products__title">
                          {currUserObject.user?.firstName +
                            " " +
                            currUserObject.user.lastName}{" "}
                          נשאר לך בקופה
                          ₪{myBank?.toLocaleString()}
                        </h2>
                        <h3 className="main-products__pretitle special-space">
                          למימוש הכסף למתנה
                          <div>בחר.י קטגוריה וקנה.י את המתנה שרצית</div>
                        </h3>


                        {/*                         <NavLink
                          to={`all-categories/?wishlistId=${13}`}
                          className="btn main-cat desktop"
                        >
                          לצפייה בקטגוריות
                          </NavLink>*/}
                       <MyBankAllCategories

                          // allCategoriesList={allCategoriesList}
                          // setAllCategoriesList={setAllCategoriesList}
                          // newEvent={true}
                          // setFilterObject={setFilterObject}



                          setShowCategMenuItem={setShowCategMenuItem}
                          setFilterObject={setShowCategMenuItem}
                          eventType={eventType}
                          setEventType={setEventType}
                          allCategoriesList={allCategoriesList}
                          setProductsFilterObject={setProductsFilterObject}
                          productsFilterObject={productsFilterObject}
                          currWishlist={currWishlist}
                        />



                        {/*  <div className="main-products__list-wrapper swiper">
                          <ul className="main-products__list products-list swiper-wrapper">
                            {visibleProducts?.length > 0
                              ? visibleProducts.map((product) => (
                                <MyBankProduct
                                  key={product?.id}
                                  data={product}
                                  updatePopupState={updatePopupState}
                                  addItemHandler={addItemHandler}
                                  openImagePopup={openImagePopup}
                                  currWishlist={currWishlist}
                                  setCurrWishlist={setCurrWishlist}
                                  currUserObject={currUserObject}
                                  goToChoosedPresentForm={
                                    goToChoosedPresentForm
                                  }
                                />
                              ))
                              : Array.from({ length: 10 }, (_, index) => (
                                <ProductPlaceholder key={index} />
                              ))}
                          </ul>

                          <div className="main-products__list-nav">
                            <div className="main-products__list-prev">
                              <img
                                src="img/svg/arrow-left-black.svg"
                                alt=""
                              />
                            </div>
                            <div className="main-products__list-next">
                              <img
                                src="img/svg/arrow-left-black.svg"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>*/}
                      </>
                    )}
                  </div>
        
                 <div className="main-my-bank__container mobile">
                    {/*<div className="main-my-bank__bottom-btns">
                      <button
                        onClick={() => {
                          setActiveTab(0);
                          toggleButton();
                        }}
                        className="btn btn-white"
                      >
                        לקנות
                      </button>
                      <button
                        onClick={() => {
                          setActiveTab(1);
                          toggleButton();
                        }}
                        className="btn green"
                      >
                        מתנות שנרכשו
                      </button>
                    </div>*/}
                    {/* <div className="main-products__filter-btn-container filter-btn-container my-bank">
                      <button
                        type="button"
                        className="filter-btn"
                        onClick={filterToggle}
                      >
                        <img
                          src="img/svg/filter-icon.png"
                          alt="filter icon"
                        />
                      </button>
                    </div>*/}
                    <div
                      className={`main-my-bank__body ${isClicked ? "open" : ""}`}
                      hidden ={myBank <=0}
                      /*hidden={activeHeroSection !== 1}*/
                    >
                      <div className="main-my-bank__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="156.143"
                          height="156.143"
                          viewBox="0 0 156.143 156.143"
                        >
                          <g
                            id="a"
                            transform="translate(-51.41 -86.97)"
                          >
                            <g
                              id="b"
                              transform="translate(52.91 88.559)"
                            >
                              <circle
                                id="Эллипс_423"
                                data-name="Эллипс 423"
                                cx="49.772"
                                cy="49.772"
                                r="49.772"
                                transform="translate(26.8 26.711)"
                                fill="#fffcef"
                                opacity="0.1"
                              />
                              <circle
                                id="Эллипс_424"
                                data-name="Эллипс 424"
                                cx="9.189"
                                cy="9.189"
                                r="9.189"
                                transform="translate(13.904 120.773)"
                                fill="#3c9e1c"
                                opacity="0.7"
                              />
                              <circle
                                id="Эллипс_425"
                                data-name="Эллипс 425"
                                cx="15.314"
                                cy="15.314"
                                r="15.314"
                                transform="translate(114.736 7.69)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <circle
                                id="Эллипс_426"
                                data-name="Эллипс 426"
                                cx="76.5"
                                cy="76.5"
                                r="76.5"
                                transform="translate(0 0.054)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                                strokeDasharray="5 5"
                              />
                            </g>
                            <g
                              id="Сгруппировать_1514"
                              data-name="Сгруппировать 1514"
                              transform="translate(52.91 88.47)"
                            >
                              <path
                                id="Контур_38461"
                                data-name="Контур 38461"
                                d="M206.053,165.042a76.542,76.542,0,1,1-11.445-40.291,76.57,76.57,0,0,1,11.445,40.291h0Z"
                                transform="translate(-52.91 -88.47)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                                strokeDasharray="0 20"
                                fillRule="evenodd"
                                opacity="0"
                              />
                              <line
                                id="Линия_534"
                                data-name="Линия 534"
                                y2="55.91"
                                transform="translate(33.112 51.048)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38462"
                                data-name="Контур 38462"
                                d="M215.95,490.488h74.4a4.862,4.862,0,0,0,4.862-4.862V471.04"
                                transform="translate(-175.548 -376.237)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="1905"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 710 60 197.5 0"
                              />
                              <path
                                id="Контур_38463"
                                data-name="Контур 38463"
                                d="M295.213,343.348V328.762a4.862,4.862,0,0,0-4.862-4.862h-74.4"
                                transform="translate(-175.548 -265.559)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38464"
                                data-name="Контур 38464"
                                d="M294.044,300.549v-1.217a4.865,4.865,0,0,0-4.862-4.862H220.86"
                                transform="translate(-179.241 -243.422)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="950"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 877.5 40 0 60"
                              />
                              <path
                                id="Контур_38465"
                                data-name="Контур 38465"
                                d="M285.491,271.119V269.9a4.865,4.865,0,0,0-4.862-4.862H215.95"
                                transform="translate(-175.548 -221.285)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="1077.5"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 78 467 0 560"
                              />
                              <path
                                id="Контур_38466"
                                data-name="Контур 38466"
                                d="M484.157,384.974H472a4.861,4.861,0,0,0-4.862,4.862v7.293A4.861,4.861,0,0,0,472,401.991h12.137A4.861,4.861,0,0,0,489,397.112c-.022-6.686-.062-17.872-.062-17.872"
                                transform="translate(-364.492 -307.186)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="497.5"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 438.76 40 0 60"
                              />
                              <path
                                id="Контур_38467"
                                data-name="Контур 38467"
                                d="M193.813,527.383a7.3,7.3,0,0,1-7.293-7.293"
                                transform="translate(-153.411 -413.133)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38468"
                                data-name="Контур 38468"
                                d="M193.813,279.626a7.293,7.293,0,1,1,0-14.586"
                                transform="translate(-153.411 -221.285)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38469"
                                data-name="Контур 38469"
                                d="M540.672,382.76a4.864,4.864,0,0,1-4.862,4.862"
                                transform="translate(-416.145 -309.834)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38470"
                                data-name="Контур 38470"
                                d="M540.672,368a4.864,4.864,0,0,0-4.862-4.862"
                                transform="translate(-416.145 -295.076)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38471"
                                data-name="Контур 38471"
                                d="M491.861,426.9a2.431,2.431,0,1,1-2.431,2.431,2.43,2.43,0,0,1,2.431-2.431Z"
                                transform="translate(-381.258 -343.036)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38472"
                                data-name="Контур 38472"
                                d="M282.546,217.331l-13.267-22.981a4.859,4.859,0,0,0-6.639-1.779L219.68,217.331"
                                transform="translate(-178.061 -174.79)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="851.26"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 716.76 134.76 0 40"
                              />
                              <line
                                id="Линия_535"
                                data-name="Линия 535"
                                x2="46.186"
                                transform="translate(53.479 104.526)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 0 40 628.76 560"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>

                      <h2 className="main-my-bank__title">הקופה שלי</h2>
                      <h3 className="main-my-bank__subtitle">
                        תוכל לממש את כספך בכל זמן ובכל מתנה שתחלום
                      </h3>
                      <div className="main-my-bank__price">
                        <p className="main-my-bank__price-text">עד עכשיו צברת</p>
                        <div className="main-my-bank__price-value">
                          <span className="text">
                            {myBank?.toLocaleString()}{" "}
                          </span>
                          <span className="money-icon"> ₪</span>
                        </div>
                      </div>

                      <div className="main-my-bank__btn-container" onClick={handleButtonClick}>
                        <a
                          href="#"
                          className="btn-white round"
                        >
                          רוצה לבחור מתנה
                        </a>
                      </div>


                     


                      {/*<div className="main-my-bank__bottom-nav-buttons">
                        <button onClick={() => setActiveHeroSection(2)}>2</button>
                        <button onClick={() => setActiveHeroSection(1)}>1</button>
                      </div>*/}

                      <div className="main-products__list-nav">
                        <div className="main-products__list-prev">
                          <img
                            src="img/svg/arrow-left-black.svg"
                            alt=""
                          />
                        </div>
                        <div className="main-products__list-next">
                          <img
                            src="img/svg/arrow-left-black.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="main-my-bank__body empty"
                      hidden={myBank >0}
                      /*hidden={activeHeroSection !== 2}*/
                    >
                      <div className="main-my-bank__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="156.143"
                          height="156.143"
                          viewBox="0 0 156.143 156.143"
                        >
                          <g
                            id="a"
                            transform="translate(-51.41 -86.97)"
                          >
                            <g
                              id="b"
                              transform="translate(52.91 88.559)"
                            >
                              <circle
                                id="Эллипс_423"
                                data-name="Эллипс 423"
                                cx="49.772"
                                cy="49.772"
                                r="49.772"
                                transform="translate(26.8 26.711)"
                                fill="#fffcef"
                                opacity="0.1"
                              />
                              <circle
                                id="Эллипс_424"
                                data-name="Эллипс 424"
                                cx="9.189"
                                cy="9.189"
                                r="9.189"
                                transform="translate(13.904 120.773)"
                                fill="#3c9e1c"
                                opacity="0.7"
                              />
                              <circle
                                id="Эллипс_425"
                                data-name="Эллипс 425"
                                cx="15.314"
                                cy="15.314"
                                r="15.314"
                                transform="translate(114.736 7.69)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <circle
                                id="Эллипс_426"
                                data-name="Эллипс 426"
                                cx="76.5"
                                cy="76.5"
                                r="76.5"
                                transform="translate(0 0.054)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                                strokeDasharray="5 5"
                              />
                            </g>
                            <g
                              id="Сгруппировать_1514"
                              data-name="Сгруппировать 1514"
                              transform="translate(52.91 88.47)"
                            >
                              <path
                                id="Контур_38461"
                                data-name="Контур 38461"
                                d="M206.053,165.042a76.542,76.542,0,1,1-11.445-40.291,76.57,76.57,0,0,1,11.445,40.291h0Z"
                                transform="translate(-52.91 -88.47)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                                strokeDasharray="0 20"
                                fillRule="evenodd"
                                opacity="0"
                              />
                              <line
                                id="Линия_534"
                                data-name="Линия 534"
                                y2="55.91"
                                transform="translate(33.112 51.048)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38462"
                                data-name="Контур 38462"
                                d="M215.95,490.488h74.4a4.862,4.862,0,0,0,4.862-4.862V471.04"
                                transform="translate(-175.548 -376.237)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="1905"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 710 60 197.5 0"
                              />
                              <path
                                id="Контур_38463"
                                data-name="Контур 38463"
                                d="M295.213,343.348V328.762a4.862,4.862,0,0,0-4.862-4.862h-74.4"
                                transform="translate(-175.548 -265.559)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38464"
                                data-name="Контур 38464"
                                d="M294.044,300.549v-1.217a4.865,4.865,0,0,0-4.862-4.862H220.86"
                                transform="translate(-179.241 -243.422)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="950"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 877.5 40 0 60"
                              />
                              <path
                                id="Контур_38465"
                                data-name="Контур 38465"
                                d="M285.491,271.119V269.9a4.865,4.865,0,0,0-4.862-4.862H215.95"
                                transform="translate(-175.548 -221.285)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="1077.5"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 78 467 0 560"
                              />
                              <path
                                id="Контур_38466"
                                data-name="Контур 38466"
                                d="M484.157,384.974H472a4.861,4.861,0,0,0-4.862,4.862v7.293A4.861,4.861,0,0,0,472,401.991h12.137A4.861,4.861,0,0,0,489,397.112c-.022-6.686-.062-17.872-.062-17.872"
                                transform="translate(-364.492 -307.186)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="497.5"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 438.76 40 0 60"
                              />
                              <path
                                id="Контур_38467"
                                data-name="Контур 38467"
                                d="M193.813,527.383a7.3,7.3,0,0,1-7.293-7.293"
                                transform="translate(-153.411 -413.133)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38468"
                                data-name="Контур 38468"
                                d="M193.813,279.626a7.293,7.293,0,1,1,0-14.586"
                                transform="translate(-153.411 -221.285)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38469"
                                data-name="Контур 38469"
                                d="M540.672,382.76a4.864,4.864,0,0,1-4.862,4.862"
                                transform="translate(-416.145 -309.834)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38470"
                                data-name="Контур 38470"
                                d="M540.672,368a4.864,4.864,0,0,0-4.862-4.862"
                                transform="translate(-416.145 -295.076)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38471"
                                data-name="Контур 38471"
                                d="M491.861,426.9a2.431,2.431,0,1,1-2.431,2.431,2.43,2.43,0,0,1,2.431-2.431Z"
                                transform="translate(-381.258 -343.036)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                              />
                              <path
                                id="Контур_38472"
                                data-name="Контур 38472"
                                d="M282.546,217.331l-13.267-22.981a4.859,4.859,0,0,0-6.639-1.779L219.68,217.331"
                                transform="translate(-178.061 -174.79)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeDashoffset="851.26"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 716.76 134.76 0 40"
                              />
                              <line
                                id="Линия_535"
                                data-name="Линия 535"
                                x2="46.186"
                                transform="translate(53.479 104.526)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                                strokeDasharray="0 0 0 0 0 40 628.76 560"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>

                      <h2 className="main-my-bank__title">הקופה שלי</h2>
                      <h3 className="main-my-bank__subtitle">
                        קופת המתנות שלך ריקה
                      </h3>
                      <div className="main-my-bank__sad-emogi">
                        <img
                          src="img/svg/sad-emoji.svg"
                          alt=""
                        />
                      </div>
                      <div className="main-my-bank__price">
                        <p className="main-my-bank__price-text">עד עכשיו צברת</p>
                        <div className="main-my-bank__price-value">
                          <span className="text">
                            {myBank?.toLocaleString()}{" "}
                          </span>
                          <span className="money-icon"> ₪</span>
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent:'center'}} className="main-my-bank__btn-container mobile-bank">

                      <div className="main-my-bank__btn-container" onClick={handleButtonClick}>
                        <a
                          href="#"
                          className="btn-white round"
                        >
                          רוצה לבחור מתנה
                        </a>
                      </div>

                        {/* <a href="#" className="btn-white">
                    רוצה לבחור מתנה
                  </a> */}
                       {/* <NavLink
                          to={`all-categories/?wishlistId=${13}`}
                          className="btn main-cat mobile"
                        >
                          To main categories
                        </NavLink>*/}
                      </div>

                     {/* <div className="main-my-bank__bottom-nav-buttons">
                        <button onClick={() => setActiveHeroSection(2)}>2</button>
                        <button onClick={() => setActiveHeroSection(1)}>1</button>
                      </div>*/}
                      {activeTab === 0 && (
                        <div className="main-products__list-wrapper my-bank-mobile">

                          {/*<div className="main-products__filter-btn-container filter-btn-container mobile">
                            <button
                              type="button"
                              className="filter-btn"
                              onClick={filterToggle}
                            >
                              <img
                                src="img/svg/filter-icon.png"
                                alt="filter icon"
                              />
                            </button>
                          </div>*/}
                          <ul className="main-products__list products-list column">
                            {visibleProducts &&
                              visibleProducts.map((product) => (
                                <MyBankProduct
                                  key={product?.id}
                                  data={product}
                                  updatePopupState={updatePopupState}
                                  addItemHandler={addItemHandler}
                                  openImagePopup={openImagePopup}
                                  currWishlist={currWishlist}
                                  setCurrWishlist={setCurrWishlist}
                                  currUserObject={currUserObject}
                                  goToChoosedPresentForm={goToChoosedPresentForm}
                                />
                              ))}
                          </ul>
                        </div>
                      )}
                      {activeTab === 1 && (
                        <div className="main-gift-basket__container desktop">
                          <div className="main-gift-basket__header">
                            <p className="main-gift-basket__header-product-name">
                              שם המוצר
                            </p>
                            <p className="main-gift-basket__header-product-price">
                              מחיר
                            </p>
                            <p className="main-gift-basket__header-product-status">
                              סטטוס הזמנה
                            </p>
                            <div className="main-gift-basket__header-product-filter">
                              <div className="header-filter__drop">
                                <button
                                  type="button"
                                  onClick={handleSortDrop}
                                  className="header-filter__button"
                                >
                       {/* <p className="text">
                                    {sortFilter.filter === "date"
                                      ? "תאריך"
                                      : sortFilter.filter === "price"
                                        ? "מחיר"
                                        : sortFilter.filter === "status"
                                          ? "סטָטוּס"
                                          : ""}
                      </p>*/}
                                </button>
                                {sortFilter.isOpen && (
                                  <div className="header-filter__body">
                                   {/* <ul className="header-filter__list">
                                      <li className="header-filter__item">
                                        <button
                                          type="button"
                                          onClick={setFilterPrice}
                                        >
                                          מחיר
                                        </button>
                                      </li>
                                      <li className="header-filter__item">
                                        <button
                                          type="button"
                                          onClick={setFilterDate}
                                        >
                                          תאריך
                                        </button>
                                      </li>
                                      <li className="header-filter__item">
                                        <button
                                          type="button"
                                          onClick={setFilterStatus}
                                        >
                                          סטָטוּס
                                        </button>
                                      </li>
                                    </ul>*/}
                                  </div>
                                )}
                              </div>

                              <button
                                type="button"
                                onClick={() =>
                                  setSortFilter({
                                    isOpen: false,
                                    filter: sortFilter.filter,
                                    sortType: "desc",
                                  })
                                }
                                className="arrow bottom"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.117"
                                  height="9.115"
                                  viewBox="0 0 17.117 9.115"
                                >
                                  <g
                                    id="Сгруппировать_912"
                                    data-name="Сгруппировать 912"
                                    transform="translate(19.135 -7.296) rotate(90)"
                                  >
                                    <g
                                      id="Сгруппировать_540"
                                      data-name="Сгруппировать 540"
                                      transform="translate(14.998 17.723) rotate(180)"
                                    >
                                      <line
                                        id="Линия_434"
                                        data-name="Линия 434"
                                        y1="7.147"
                                        x2="6.289"
                                        transform="translate(0)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                      />
                                      <path
                                        id="Контур_6897"
                                        data-name="Контур 6897"
                                        d="M0,0,6.289,6.861"
                                        transform="translate(0 7.432)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  setSortFilter({
                                    isOpen: false,
                                    filter: sortFilter.filter,
                                    sortType: "asc",
                                  })
                                }
                                className="arrow top"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.117"
                                  height="9.115"
                                  viewBox="0 0 17.117 9.115"
                                >
                                  <g
                                    id="Сгруппировать_913"
                                    data-name="Сгруппировать 913"
                                    transform="translate(19.708 -6.837) rotate(90)"
                                  >
                                    <g
                                      id="Сгруппировать_539"
                                      data-name="Сгруппировать 539"
                                      transform="translate(8.25 4.002)"
                                    >
                                      <line
                                        id="Линия_434"
                                        data-name="Линия 434"
                                        y1="7.147"
                                        x2="6.289"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                      />
                                      <path
                                        id="Контур_6897"
                                        data-name="Контур 6897"
                                        d="M0,0,6.289,6.861"
                                        transform="translate(0 7.432)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>

                          <div className="main-gift-basket__purchased">
                            <div className="main-gift-basket__body">
                              <ul className="main-gift-basket__my-purchases-list">
                                {boughtForBankMoney &&
                                  boughtForBankMoney.map((product, index) => (
                                    <li
                                      key={index}
                                      className="main-gift-basket__my-purchases-item"
                                    >
                                      <MyBankSinglePurchase
                                        data={product}
                                        currUserObject={currUserObject}
                                        currWishlist={currWishlist}
                                        setCurrWishlist={setCurrWishlist}
                                      />
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}

                       {activeTab === 2 && (
                        <div className="main-gift-basket__container desktop">
                          <div className="main-gift-basket__header">
                            <p className="main-gift-basket__header-product-name">
                              שם המוצר
                            </p>
                            <p className="main-gift-basket__header-product-price">
                              מחיר
                            </p>
                            <p className="main-gift-basket__header-product-status">
                              סטטוס הזמנה
                            </p>
                        
                            <div className="main-gift-basket__header-product-filter">
                              <div className="header-filter__drop">
                                <button
                                  type="button"
                                  onClick={handleSortDrop}
                                  className="header-filter__button"
                                >
                       {/* <p className="text">
                                    {sortFilter.filter === "date"
                                      ? "תאריך"
                                      : sortFilter.filter === "price"
                                        ? "מחיר"
                                        : sortFilter.filter === "status"
                                          ? "סטָטוּס"
                                          : ""}
                      </p>*/}
                                </button>
                                {sortFilter.isOpen && (
                                  <div className="header-filter__body">
                                   {/* <ul className="header-filter__list">
                                      <li className="header-filter__item">
                                        <button
                                          type="button"
                                          onClick={setFilterPrice}
                                        >
                                          מחיר
                                        </button>
                                      </li>
                                      <li className="header-filter__item">
                                        <button
                                          type="button"
                                          onClick={setFilterDate}
                                        >
                                          תאריך
                                        </button>
                                      </li>
                                      <li className="header-filter__item">
                                        <button
                                          type="button"
                                          onClick={setFilterStatus}
                                        >
                                          סטָטוּס
                                        </button>
                                      </li>
                                    </ul>*/}
                                  </div>
                                )}
                              </div>

                              <button
                                type="button"
                                onClick={() =>
                                  setSortFilter({
                                    isOpen: false,
                                    filter: sortFilter.filter,
                                    sortType: "desc",
                                  })
                                }
                                className="arrow bottom"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.117"
                                  height="9.115"
                                  viewBox="0 0 17.117 9.115"
                                >
                                  <g
                                    id="Сгруппировать_912"
                                    data-name="Сгруппировать 912"
                                    transform="translate(19.135 -7.296) rotate(90)"
                                  >
                                    <g
                                      id="Сгруппировать_540"
                                      data-name="Сгруппировать 540"
                                      transform="translate(14.998 17.723) rotate(180)"
                                    >
                                      <line
                                        id="Линия_434"
                                        data-name="Линия 434"
                                        y1="7.147"
                                        x2="6.289"
                                        transform="translate(0)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                      />
                                      <path
                                        id="Контур_6897"
                                        data-name="Контур 6897"
                                        d="M0,0,6.289,6.861"
                                        transform="translate(0 7.432)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  setSortFilter({
                                    isOpen: false,
                                    filter: sortFilter.filter,
                                    sortType: "asc",
                                  })
                                }
                                className="arrow top"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.117"
                                  height="9.115"
                                  viewBox="0 0 17.117 9.115"
                                >
                                  <g
                                    id="Сгруппировать_913"
                                    data-name="Сгруппировать 913"
                                    transform="translate(19.708 -6.837) rotate(90)"
                                  >
                                    <g
                                      id="Сгруппировать_539"
                                      data-name="Сгруппировать 539"
                                      transform="translate(8.25 4.002)"
                                    >
                                      <line
                                        id="Линия_434"
                                        data-name="Линия 434"
                                        y1="7.147"
                                        x2="6.289"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                      />
                                      <path
                                        id="Контур_6897"
                                        data-name="Контур 6897"
                                        d="M0,0,6.289,6.861"
                                        transform="translate(0 7.432)"
                                        fill="none"
                                        stroke="#000"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>

                          <div className="main-gift-basket__purchased">
                            <div className="main-gift-basket__body">
                              <ul className="main-gift-basket__my-purchases-list">
                                {boughtForBankMoney &&
                                  boughtForBankMoney.map((product, index) => (
                                    <li
                                      key={index}
                                      className="main-gift-basket__my-purchases-item"
                                    >
                                      <MyBankSinglePurchase
                                        data={product}
                                        currUserObject={currUserObject}
                                        currWishlist={currWishlist}
                                        setCurrWishlist={setCurrWishlist}
                                      />
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {activeTab === 1 && (
                    <div className="main-gift-basket__container mobile">
                      <div className="main-gift-basket__header">
                        <p className="main-gift-basket__header-product-name">
                          שם המוצר
                        </p>
                        <p className="main-gift-basket__header-product-price">
                          מחיר
                        </p>
                        <p className="main-gift-basket__header-product-status">
                          סטטוס הזמנה
                        </p>
                        <div className="main-gift-basket__header-product-filter">
                          <div className="header-filter__drop">
                            <button
                              type="button"
                              /*onClick={handleSortDrop}*/
                              className="header-filter__button"
                            >
                  
                              <p className="text">
                                {sortFilter.filter === "date"
                                  ? "תאריך"
                                  : sortFilter.filter === "price"
                                    ? "מחיר"
                                    : sortFilter.filter === "status"
                                      ? "סטָטוּס"
                                      : ""}
                              </p>
                            </button>
                            {sortFilter.isOpen && (
                              <div className="header-filter__body">
                                <ul className="header-filter__list">
                                  <li className="header-filter__item">
                                    <button
                                      type="button"
                                      onClick={setFilterPrice}
                                    >
                                      מחיר
                                    </button>
                                  </li>
                                  <li className="header-filter__item">
                                    <button
                                      type="button"
                                      onClick={setFilterDate}
                                    >
                                      תאריך
                                    </button>
                                  </li>
                                  <li className="header-filter__item">
                                    <button
                                      type="button"
                                      onClick={setFilterStatus}
                                    >
                                      סטָטוּס
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>

                          <button
                            type="button"
                            onClick={() =>
                              setSortFilter({
                                isOpen: false,
                                filter: sortFilter.filter,
                                sortType: "desc",
                              })
                            }
                            className="arrow bottom"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.117"
                              height="9.115"
                              viewBox="0 0 17.117 9.115"
                            >
                              <g
                                id="Сгруппировать_912"
                                data-name="Сгруппировать 912"
                                transform="translate(19.135 -7.296) rotate(90)"
                              >
                                <g
                                  id="Сгруппировать_540"
                                  data-name="Сгруппировать 540"
                                  transform="translate(14.998 17.723) rotate(180)"
                                >
                                  <line
                                    id="Линия_434"
                                    data-name="Линия 434"
                                    y1="7.147"
                                    x2="6.289"
                                    transform="translate(0)"
                                    fill="none"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeWidth={2}
                                  />
                                  <path
                                    id="Контур_6897"
                                    data-name="Контур 6897"
                                    d="M0,0,6.289,6.861"
                                    transform="translate(0 7.432)"
                                    fill="none"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeWidth={2}
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              setSortFilter({
                                isOpen: false,
                                filter: sortFilter.filter,
                                sortType: "asc",
                              })
                            }
                            className="arrow top"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.117"
                              height="9.115"
                              viewBox="0 0 17.117 9.115"
                            >
                              <g
                                id="Сгруппировать_913"
                                data-name="Сгруппировать 913"
                                transform="translate(19.708 -6.837) rotate(90)"
                              >
                                <g
                                  id="Сгруппировать_539"
                                  data-name="Сгруппировать 539"
                                  transform="translate(8.25 4.002)"
                                >
                                  <line
                                    id="Линия_434"
                                    data-name="Линия 434"
                                    y1="7.147"
                                    x2="6.289"
                                    transform="translate(0 0)"
                                    fill="none"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeWidth={2}
                                  />
                                  <path
                                    id="Контур_6897"
                                    data-name="Контур 6897"
                                    d="M0,0,6.289,6.861"
                                    transform="translate(0 7.432)"
                                    fill="none"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeWidth={2}
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="main-gift-basket__purchased">
                        <div className="main-gift-basket__body">
                          <ul className="main-gift-basket__my-purchases-list">
                            {boughtForBankMoney &&
                              boughtForBankMoney.map((product, index) => (
                                <>
                                  {/* isStatusRelevantByCartType(product.status) ? ( */}
                                  <li
                                    key={index}
                                    className="main-gift-basket__my-purchases-item"
                                  >
                                    <MyBankSinglePurchase
                                      data={product}
                                      currUserObject={currUserObject}
                                      currWishlist={currWishlist}
                                      setCurrWishlist={setCurrWishlist}
                                    />
                                  </li>
                                  {/* ) : (
                            ""
                          ) */}
                                </>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}








                  {activeTab === 2 && (
                    <div className="main-gift-basket__container mobile">
                      <div className="main-gift-basket__header">
                        <p className="main-gift-basket__header-product-name">
                          שם המוצר
                        </p>
                        <p className="main-gift-basket__header-product-price">
                          מחיר
                        </p>
                        <p style={{marginRight: '4vw'}} className="main-gift-basket__header-product-status">
                          סטטוס הזמנה
                        </p>
                        <p style={{marginRight: '4vw'}} className="main-gift-basket__header-product-status">
                          למי רכשתי
                        </p>
                        <div className="main-gift-basket__header-product-filter">
                          <div className="header-filter__drop">
                            <button
                              type="button"
                              /*onClick={handleSortDrop}*/
                              className="header-filter__button"
                            >
                  
                              <p className="text">
                                {sortFilter.filter === "date"
                                  ? "תאריך"
                                  : sortFilter.filter === "price"
                                    ? "מחיר"
                                    : sortFilter.filter === "status"
                                      ? "סטָטוּס"
                                      : ""}
                              </p>
                            </button>
                            {sortFilter.isOpen && (
                              <div className="header-filter__body">
                                <ul className="header-filter__list">
                                  <li className="header-filter__item">
                                    <button
                                      type="button"
                                      onClick={setFilterPrice}
                                    >
                                      מחיר
                                    </button>
                                  </li>
                                  <li className="header-filter__item">
                                    <button
                                      type="button"
                                      onClick={setFilterDate}
                                    >
                                      תאריך
                                    </button>
                                  </li>
                                  <li className="header-filter__item">
                                    <button
                                      type="button"
                                      onClick={setFilterStatus}
                                    >
                                      סטָטוּס
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>

                          <button
                            type="button"
                            onClick={() =>
                              setSortFilter({
                                isOpen: false,
                                filter: sortFilter.filter,
                                sortType: "desc",
                              })
                            }
                            className="arrow bottom"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.117"
                              height="9.115"
                              viewBox="0 0 17.117 9.115"
                            >
                              <g
                                id="Сгруппировать_912"
                                data-name="Сгруппировать 912"
                                transform="translate(19.135 -7.296) rotate(90)"
                              >
                                <g
                                  id="Сгруппировать_540"
                                  data-name="Сгруппировать 540"
                                  transform="translate(14.998 17.723) rotate(180)"
                                >
                                  <line
                                    id="Линия_434"
                                    data-name="Линия 434"
                                    y1="7.147"
                                    x2="6.289"
                                    transform="translate(0)"
                                    fill="none"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeWidth={2}
                                  />
                                  <path
                                    id="Контур_6897"
                                    data-name="Контур 6897"
                                    d="M0,0,6.289,6.861"
                                    transform="translate(0 7.432)"
                                    fill="none"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeWidth={2}
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              setSortFilter({
                                isOpen: false,
                                filter: sortFilter.filter,
                                sortType: "asc",
                              })
                            }
                            className="arrow top"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.117"
                              height="9.115"
                              viewBox="0 0 17.117 9.115"
                            >
                              <g
                                id="Сгруппировать_913"
                                data-name="Сгруппировать 913"
                                transform="translate(19.708 -6.837) rotate(90)"
                              >
                                <g
                                  id="Сгруппировать_539"
                                  data-name="Сгруппировать 539"
                                  transform="translate(8.25 4.002)"
                                >
                                  <line
                                    id="Линия_434"
                                    data-name="Линия 434"
                                    y1="7.147"
                                    x2="6.289"
                                    transform="translate(0 0)"
                                    fill="none"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeWidth={2}
                                  />
                                  <path
                                    id="Контур_6897"
                                    data-name="Контур 6897"
                                    d="M0,0,6.289,6.861"
                                    transform="translate(0 7.432)"
                                    fill="none"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeWidth={2}
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="main-gift-basket__purchased">
                        <div className="main-gift-basket__body">
                          <ul className="main-gift-basket__my-purchases-list">
                            {generalBought &&
                              generalBought.map((product, index) => (
                                <>
                                  {/* isStatusRelevantByCartType(product.status) ? ( */}
                                  <li
                                    key={index}
                                    className="main-gift-basket__my-purchases-item"
                                  >
                                    <MyBankSinglePurchase
                                      data={product}
                                      currUserObject={currUserObject}
                                      currWishlist={currWishlist}
                                      setCurrWishlist={setCurrWishlist}
                                      isGeneral = {true}
                                    />
                                  </li>
                                  {/* ) : (
                            ""
                          ) */}
                                </>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </section>

                {activeTab == 0 && (
                  <>
                    <section className="products__main main-products main-products_related">
                      <div className="main-products__container">
                        <h2 className="main-products__title">מוצרים נילווים</h2>
                        <h3 className="main-products__subtitle">
                          שיתאימו לך בול
                        </h3>

                        <div className="main-products__slider-block">
                          <div className="main-products__slider swiper">
                            <div className="main-products__wrapper swiper-wrapper">
                              <Swiper
                                style={{
                                  position: "relative",
                                  marginTop: "30px",
                                  padding: "0.52083vw",
                                }}
                                ref={mainProductsRelated}
                                slidesPerView={slidesPerView}
                                spaceBetween={40}
                                onSlideChange={(swiper) => {
                                  setActiveIndexRecent(swiper?.realIndex);
                                }}
                              >
                                {visibleProducts &&
                                  visibleProducts.map((product, index) => (
                                    <SwiperSlide
                                      key={product?.id}
                                      className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                                        }`}
                                      onClick={() => handleThumbnailClick(index)}
                                    // style={{padding:"0.75rem"}}
                                    >
                                      <MyBankProduct
                                        key={product?.id}
                                        data={product}
                                        updatePopupState={updatePopupState}
                                        addItemHandler={addItemHandler}
                                        openImagePopup={openImagePopup}
                                        currWishlist={currWishlist}
                                        setCurrWishlist={setCurrWishlist}
                                        currUserObject={currUserObject}
                                        goToChoosedPresentForm={
                                          goToChoosedPresentForm
                                        }
                                      />
                                    </SwiperSlide>
                                  ))}
                              </Swiper>
                            </div>
                          </div>

                          <div className="main-products__slider-nav">
                            <div
                              className="main-products__slider-prev"
                              style={{
                                display: `${relatedPrevActive ? "none" : "flex"}`,
                              }}
                              onClick={() =>
                                handleSlideStep(
                                  mainProductsRelated.current.swiper,
                                  "prev",
                                )
                              }
                            >
                              <img
                                src={arrowLeftBlack}
                                alt=""
                              />
                            </div>
                            <div
                              className="main-products__slider-next"
                              style={{
                                display: `${mainProductsRelated.current?.swiper?.slides
                                  .length -
                                  3 ==
                                  mainProductsRelated.current?.swiper?.realIndex
                                  ? "none"
                                  : "flex"
                                  }`,
                              }}
                              onClick={() =>
                                handleSlideStep(
                                  mainProductsRelated.current.swiper,
                                  "next",
                                )
                              }
                            >
                              <img
                                src={arrowLeftBlack}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>

                        <div className="main-products__btn-container btn-container_center">
                          <a
                            href="#"
                            className="btn-outline-white"
                          >
                            לא מצאתי רוצה לראות עוד מוצרים
                          </a>
                        </div>
                      </div>
                    </section>

                    <section className="products__main main-products main-products_recent">
                      <div className="main-products__container">
                        <h2 className="main-products__title">
                          מוצרים אחרונים שחיפשתי
                        </h2>
                        <h3 className="main-products__subtitle">
                          שיתאימו לך בול
                        </h3>

                        <div className="main-products__slider-block">
                          <div className="main-products__slider swiper">
                            <div className="main-products__wrapper swiper-wrapper">
                              <Swiper
                                style={{
                                  position: "relative",
                                  marginTop: "30px",
                                  padding: "0.52083vw",
                                }}
                                ref={mainProductsRecent}
                                slidesPerView={slidesPerView}
                                spaceBetween={40}
                                onSlideChange={(swiper) => {
                                  setActiveIndexRecent(swiper?.realIndex);
                                }}
                              >
                                {visibleProducts &&
                                  visibleProducts.map((product, index) => (
                                    <SwiperSlide
                                      key={product?.id}
                                      className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                                        }`}
                                      onClick={() => handleThumbnailClick(index)}
                                    // style={{padding:"0.75rem"}}
                                    >
                                      <MyBankProduct
                                        key={product?.id}
                                        data={product}
                                        updatePopupState={updatePopupState}
                                        addItemHandler={addItemHandler}
                                        openImagePopup={openImagePopup}
                                        currWishlist={currWishlist}
                                        setCurrWishlist={setCurrWishlist}
                                        currUserObject={currUserObject}
                                        goToChoosedPresentForm={
                                          goToChoosedPresentForm
                                        }
                                      />
                                    </SwiperSlide>
                                  ))}
                              </Swiper>
                            </div>
                          </div>
                          <div className="main-products__slider-nav">
                            <div
                              className="main-products__slider-prev"
                              style={{
                                display: `${recentPrevActive ? "none" : "flex"}`,
                              }}
                              onClick={() =>
                                handleSlideStep(
                                  mainProductsRecent.current.swiper,
                                  "prev",
                                )
                              }
                            >
                              <img
                                src={arrowLeftBlack}
                                alt=""
                              />
                            </div>
                            <div
                              className="main-products__slider-next"
                              style={{
                                display: `${mainProductsRecent.current?.swiper?.slides
                                  .length -
                                  3 ==
                                  mainProductsRecent.current?.swiper?.realIndex
                                  ? "none"
                                  : "flex"
                                  }`,
                              }}
                              onClick={() =>
                                handleSlideStep(
                                  mainProductsRecent.current.swiper,
                                  "next",
                                )
                              }
                            >
                              <img
                                src={arrowLeftBlack}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>

                        <div className="main-products__btn-container btn-container_center">
                          <a
                            href="#"
                            className="btn-outline-white"
                          >
                            לא מצאתי רוצה לראות עוד מוצרים
                          </a>
                        </div>
                      </div>
                    </section>
                  </>
                )}
              </main>

              {isFilterOpen && (
                <ProductsFilter
                  filterToggle={filterToggle}
                  allCategoriesList={allCategoriesList}
                  // choosedCategoriesList={choosedCategoriesList}
                  // setChoosedCategoriesList={setChoosedCategoriesList}
                  filterObject={filterObject}
                  setFilterObject={setFilterObject}
                  filterFunction={handleFilterChecks}
                  manufacturers={manufacturers}
                  rangePriceMinMax={rangePriceMinMax}
                />
              )}


            </>
          }
        />
        <Route
          path="buy/*"
          element={
            <main
              className={`credentials-page ${isForm ? "credentials-page_form" : ""
                } ${isOrange ? "credentials-page_orange" : ""} ${sendChoosedPresentStep.blessingWrite ||
                  sendMoneyStep.blessingWrite
                  ? "blessing-write-title"
                  : sendChoosedPresentStep.shipping
                    ? "shipping-type"
                    : sendMoneyStep.howMuchForPresent
                      ? "how-much"
                      : sendChoosedPresentStep.moneyPresent ||
                        sendMoneyStep.moneyPresent
                        ? "money-present"
                        : sendChoosedPresentStep.payment || sendMoneyStep.payment
                          ? "payment-page"
                          : sendChoosedPresentStep.terms
                            ? "terms-form"
                            : ""
                }`}
              data-fullscreen
            >
              <section
                className={`credentials-section ${isGreenMobile ? "green-mobile" : ""
                  }`}
              >
                <div className="credentials-section__bg-image-ibg">
                  <img
                    src={mainBgImage.main}
                    className="img-people"
                    alt="bg"
                  />
                  <img
                    src={mainBgImage.noPeople}
                    className="img-no-people"
                    alt="bg"
                  />
                </div>
                <div className="credentials-section__content-window">
                  {/* {presentType === "present" && ( */}
                  <MyBankCreateOrder
                    sendChoosedPresentStep={sendChoosedPresentStep}
                    setSendChoosedPresentStep={setSendChoosedPresentStep}
                    progress={progress}
                    setProgress={setProgress}
                    stepNumber={stepNumber}
                    setStepNumber={setStepNumber}
                    setMainBgImage={setMainBgImage}
                    setOrange={setOrange}
                    setGreenMobile={setGreenMobile}
                    setForm={setForm}
                    setChoosed={setChoosed}
                    resultObject={resultObject}
                    setResultObject={setResultObject}
                    choosedPresent={choosedPresent}
                    wishListInfo={wishListInfo}
                    isOrange={isOrange}
                    languageToggle={languageToggle}
                    language={language}
                    resetMyBankOrderFlow={resetMyBankOrderFlow}
                    updateUserBank={updateUserBank}
                  />
                  {/* )} */}
                </div>
                <div className="credentials-section__content-window">
                  {/* {presentType === "present" && ( */}
                  <MyBankCreateOrder1
                    sendChoosedPresentStep={sendChoosedPresentStep}
                    setSendChoosedPresentStep={setSendChoosedPresentStep}
                    progress={progress}
                    setProgress={setProgress}
                    stepNumber={stepNumber}
                    setStepNumber={setStepNumber}
                    setMainBgImage={setMainBgImage}
                    setOrange={setOrange}
                    setGreenMobile={setGreenMobile}
                    setForm={setForm}
                    setChoosed={setChoosed}
                    resultObject={resultObject}
                    setResultObject={setResultObject}
                    choosedPresent={choosedPresent}
                    wishListInfo={wishListInfo}
                    isOrange={isOrange}
                    languageToggle={languageToggle}
                    language={language}
                    resetMyBankOrderFlow={resetMyBankOrderFlow}
                    updateUserBank={updateUserBank}
                  />
                  {/* )} */}
                </div>
              </section>
            </main>
          }
        />

        <Route
          path="single-product/:productId"
          element={
            <ProductPage
              updatePopupState={updatePopupState}
              addItemHandler={addItemHandler}
              setCurrWishlist={setCurrWishlist}
              currWishlist={currWishlist}
              openImagePopup={openImagePopup}
              currUserObject={currUserObject}
              setCurrUserObject={setCurrUserObject}
              goToChoosedPresentForm={goToChoosedPresentForm}
            />
          }
        />
        <Route
          path="/all-categories/*"
          element={
            <MyBankAllCategories

              // allCategoriesList={allCategoriesList}
              // setAllCategoriesList={setAllCategoriesList}
              // newEvent={true}
              // setFilterObject={setFilterObject}



              setShowCategMenuItem={setShowCategMenuItem}
              setFilterObject={setShowCategMenuItem}
              eventType={eventType}
              setEventType={setEventType}
              allCategoriesList={allCategoriesList}
              setProductsFilterObject={setProductsFilterObject}
              productsFilterObject={productsFilterObject}
              currWishlist={currWishlist}
            />
          }
        />

        <Route
          path="/category/:categoryId/*"
          element={
            <MyBankCategory
              allCategoriesList={allCategoriesList}
              newEvent={false}
            />
          }
        />

        <Route
          path="/products/:categoryId/:productsId/*"
          element={
            <MyBankProducts
              updatePopupState={updatePopupState}
              isFilterOpen={isFilterOpen}
              addItemHandler={addItemHandler}
              openImagePopup={openImagePopup}
              currWishlist={currWishlist}
              setCurrWishlist={setCurrWishlist}
              currUserObject={currUserObject}
              filterToggle={filterToggle}
              allCategoriesList={allCategoriesList}
              newEvent={true}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              language={language}
              languageToggle={languageToggle}
              setCurrUserObject={setCurrUserObject}
            />
          }
        />
      </Routes>
      {notEnoughMoneyPopup ? (
        <NotEnoughMoneyPopup
          updatePopupState={updatePopupState}
          title="אין מספיק כסף בקופה"
          setPopup={setNotEnoughMoneyPopup}
        />
      ) : (
        ""
      )}
      <div className="form-credentials-section__buttons-container mobile">
        <button
          type="submit"
          className="btn footer-mobile"
          style={{ justifyContent: "space-between", display: "flex", overflow: "hidden", padding:0}}    
        
        >
          <div style={{ flex: 1}}>
            <NavLink
              to="/"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.516"
                  height="25.445"
                  viewBox="0 0 25.516 25.445"
                >
                  <path
                    id="home_1_"
                    data-name="home (1)"
                    d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                    transform="translate(0.771 0.601)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.2"
                  />
                </svg>
              </div>
              <p className="text1">ראשי</p>
            </NavLink>
          </div>
          <div style={{ flex: 1 }}>
          <NavLink to="/contact-us" >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={27}
                height={26}
                viewBox="0 0 27 26"
              >
                <g
                  id="Сгруппировать_1499"
                  data-name="Сгруппировать 1499"
                  transform="translate(1177 -2115)"
                >
                  <g
                    id="Эллипс_411"
                    data-name="Эллипс 411"
                    transform="translate(-1177 2115)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <g
                    id="Эллипс_412"
                    data-name="Эллипс 412"
                    transform="translate(-1159 2122)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <g
                    id="Эллипс_413"
                    data-name="Эллипс 413"
                    transform="translate(-1175 2132)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                  </g>
                  <line
                    id="Линия_524"
                    data-name="Линия 524"
                    x2={10}
                    y2={4}
                    transform="translate(-1168.5 2120.5)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                  <line
                    id="Линия_525"
                    data-name="Линия 525"
                    y1={6}
                    x2={9}
                    transform="translate(-1166.5 2128.5)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </span>
            <p className="text1">צרו קשר</p>
          </NavLink>
          </div>
          <div style={{ padding:' 1.2vh 0 1.2vh 0',flex: 1, backgroundColor:  "#ec1b53" }}>
          <NavLink
            to="/my-bank"
          >
            <span className="icon">
              <svg
                id="Artboard_1"
                data-name="Artboard 1"
                xmlns="http://www.w3.org/2000/svg"
                width="23.901"
                height="24.498"
                viewBox="0 0 23.901 24.498"
              >
                <path
                  id="Контур_10114"
                  data-name="Контур 10114"
                  d="M15.336,58.018h2.227v-.891H15.336a.445.445,0,0,1-.445-.445V51.336a.445.445,0,0,1,.445-.445h2.227V50H15.336A1.336,1.336,0,0,0,14,51.336v5.345A1.336,1.336,0,0,0,15.336,58.018Z"
                  transform="translate(6.338 -33.519)"
                  fill="white"
                />
                <path
                  id="Контур_10115"
                  data-name="Контур 10115"
                  d="M16,54h.891v.891H16V54Z"
                  transform="translate(6.12 -35.737)"
                  fill="white"
                />
                <path
                  id="Контур_10116"
                  data-name="Контур 10116"
                  d="M35.254,49.454h.891v-.891h-.891a.445.445,0,0,1-.347-.169C34.131,47.374,32,45,29.018,45H22.782a1.782,1.782,0,1,0,0,3.563h5.345v-.891H22.782a.891.891,0,1,1,0-1.782h6.236c2.579,0,4.485,2.129,5.189,3.042A1.336,1.336,0,0,0,35.254,49.454Z"
                  transform="translate(-15.361 -30.746)"
                  fill="white"
                />
                <path
                  id="Контур_10117"
                  data-name="Контур 10117"
                  d="M33.9,52.245h7.884v-.891H33.9a3.819,3.819,0,0,1-1.2-.2l-4.1-1.381a2.389,2.389,0,0,1-.848-.5l-5.7-5.207a.7.7,0,0,1-.187-.733.614.614,0,0,1,.566-.443.6.6,0,0,1,.3.085l6.018,3.7.407-.775-6.039-3.7a1.3,1.3,0,0,0-.689-.2,1.435,1.435,0,0,0-1.34,1.027,1.648,1.648,0,0,0,.432,1.726l5.7,5.207a3.164,3.164,0,0,0,1.116.668l4.1,1.381A4.554,4.554,0,0,0,33.9,52.245Z"
                  transform="translate(-21 -29.083)"
                  fill="white"
                />
                <rect
                  id="Прямоугольник_2304"
                  data-name="Прямоугольник 2304"
                  width="1.4"
                  height="2.8"
                  transform="translate(7.107 2.8)"
                  fill="white"
                />
                <rect
                  id="Прямоугольник_2305"
                  data-name="Прямоугольник 2305"
                  width="0.7"
                  height="2.8"
                  transform="translate(9.207)"
                  fill="white"
                />
                <rect
                  id="Прямоугольник_2306"
                  data-name="Прямоугольник 2306"
                  width="0.7"
                  height="2.8"
                  transform="translate(13.407 8.399)"
                  fill="white"
                />
                <rect
                  id="Прямоугольник_2307"
                  data-name="Прямоугольник 2307"
                  width="1.4"
                  height="1.4"
                  transform="translate(14.807 11.899)"
                  fill="white"
                />
                <path
                  id="Контур_10118"
                  data-name="Контур 10118"
                  d="M33.991,19.771a1.782,1.782,0,0,0,1.287-.494,1.987,1.987,0,0,0,.414-1.938,4.789,4.789,0,0,0-3.278-3.278,1.822,1.822,0,0,0-2.352,2.352,4.789,4.789,0,0,0,3.278,3.278,2.82,2.82,0,0,0,.65.08Zm-2.227-4.9a2.058,2.058,0,0,1,.445.053,3.959,3.959,0,0,1,2.619,2.619,1.212,1.212,0,0,1-.178,1.1,1.212,1.212,0,0,1-1.1.178,3.959,3.959,0,0,1-2.619-2.619,1.212,1.212,0,0,1,.178-1.1.926.926,0,0,1,.659-.232Z"
                  transform="translate(-18.31 -13.535)"
                  fill="white"
                />
                <path
                  id="Контур_10119"
                  data-name="Контур 10119"
                  d="M35.088,21.742a1.782,1.782,0,0,0,1.3-.494l1.336-1.336-.628-.628L35.756,20.62c-.445.445-1.7.241-2.744-.806-.94-.94-1.336-2.227-.8-2.739l1.336-1.336-.628-.628-1.336,1.336c-.891.891-.543,2.673.8,4.009a4.04,4.04,0,0,0,2.7,1.287Z"
                  transform="translate(-20.664 -14.17)"
                  fill="white"
                />
                <path
                  id="Контур_10120"
                  data-name="Контур 10120"
                  d="M49.745,33.234a3.4,3.4,0,0,0,2.717-2.169,3.924,3.924,0,0,0,.316-2.882,1.755,1.755,0,0,0-.891-1.033c-1.114-.53-2.61.356-3.408,2.018a3.924,3.924,0,0,0-.316,2.882,1.755,1.755,0,0,0,.891,1.033h0a1.621,1.621,0,0,0,.69.151Zm1.474-5.345a.641.641,0,0,1,.3.062.846.846,0,0,1,.419.521,3.065,3.065,0,0,1-.276,2.2c-.615,1.274-1.661,1.866-2.227,1.6a.846.846,0,0,1-.419-.521,3.064,3.064,0,0,1,.276-2.2A2.624,2.624,0,0,1,51.22,27.889Z"
                  transform="translate(-42.772 -20.762)"
                  fill="white"
                />
                <path
                  id="Контур_10121"
                  data-name="Контур 10121"
                  d="M46.495,34.332a3.4,3.4,0,0,0,2.713-2.174c.788-1.639.552-3.354-.535-3.9l-1.782-.891-.4.8,1.782.891c.561.276.748,1.434.134,2.713s-1.661,1.866-2.227,1.6L44.4,32.479l-.4.8,1.822.891a1.55,1.55,0,0,0,.668.165Z"
                  transform="translate(-37.854 -20.969)"
                  fill="white"
                />
                <rect
                  id="Прямоугольник_2308"
                  data-name="Прямоугольник 2308"
                  width="0.7"
                  height="0.7"
                  transform="translate(9.207 3.5)"
                  fill="white"
                />
              </svg>
            </span>
            <p className="text1" style={{color: "white"}}>הקופה שלי</p>

          </NavLink>
          </div>
          <div style={{ flex: 1}}>
          <NavLink
            //onClick={() => goToCartByWishlist1()}
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.846"
                height="24.798"
                viewBox="0 0 19.846 24.798"
              >
                <g
                  id="Сгруппировать_74057"
                  data-name="Сгруппировать 74057"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Artboard_2"
                    data-name="Artboard 2"
                    d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                    transform="translate(-74.992 -18.61)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth={1}
                  />
                  <path
                    id="heart"
                    d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                    transform="translate(-25.26 -15.21)"
                    fill="#ec1b53"
                  />
                </g>
              </svg>
            </span>
            <p className="text1">סל המתנות</p>
          </NavLink>
          </div>
          <div style={{ flex: 1}}>
          <NavLink
            to="/my-events"
            onClick={() => setMyEventsActiveTab(0)}

          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29.959"
                height="22.842"
                viewBox="0 0 29.959 22.842"
              >
                <g
                  id="Сгруппировать_74063"
                  data-name="Сгруппировать 74063"
                  transform="translate(-14 0.5)"
                >
                  <g
                    id="Artboard_3"
                    data-name="Artboard 3"
                    transform="translate(22.214 0)"
                  >
                    <path
                      id="Контур_38424"
                      data-name="Контур 38424"
                      d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                      transform="translate(-24.307 -20.873)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38425"
                      data-name="Контур 38425"
                      d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                      transform="translate(-20.77 -14.613)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38426"
                      data-name="Контур 38426"
                      d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                      transform="translate(-14.469 -14.389)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_526"
                      data-name="Линия 526"
                      y2="4.793"
                      transform="translate(9.839 13.419)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_527"
                      data-name="Линия 527"
                      y2="8.147"
                      transform="translate(7.443 11.023)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_528"
                      data-name="Линия 528"
                      y2="11.684"
                      transform="translate(5.047 8.445)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <line
                      id="Линия_529"
                      data-name="Линия 529"
                      y2="7.055"
                      transform="translate(2.651 14.033)"
                      fill="none"
                      stroke="#707070"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                    <circle
                      id="Эллипс_414"
                      data-name="Эллипс 414"
                      cx="1.438"
                      cy="1.438"
                      r="1.438"
                      transform="translate(10.319)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38427"
                      data-name="Контур 38427"
                      d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                      transform="translate(-27.244 -17.922)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38428"
                      data-name="Контур 38428"
                      d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                      transform="translate(-31.988 -10.775)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38429"
                      data-name="Контур 38429"
                      d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                      transform="translate(-20.474 -9.487)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_38430"
                      data-name="Контур 38430"
                      d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                      transform="translate(-28.181 -7.104)"
                      fill="none"
                      stroke="#707070"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                  <g
                    id="Сгруппировать_1710"
                    data-name="Сгруппировать 1710"
                    transform="translate(14 2.322)"
                  >
                    <g
                      id="Контур_10101"
                      data-name="Контур 10101"
                      transform="translate(0 1.369)"
                      fill="#3c9e1c"
                    >
                      <path
                        d="M 6.844831466674805 13.18966102600098 C 3.3462815284729 13.18966102600098 0.5000014901161194 10.34338188171387 0.5000014901161194 6.844831466674805 C 0.5000014901161194 3.3462815284729 3.3462815284729 0.5000014901161194 6.844831466674805 0.5000014901161194 C 10.34338188171387 0.5000014901161194 13.18966102600098 3.3462815284729 13.18966102600098 6.844831466674805 C 13.18966102600098 10.34338188171387 10.34338188171387 13.18966102600098 6.844831466674805 13.18966102600098 Z"
                        stroke="none"
                      />
                      <path
                        d="M 6.844831466674805 1.000001907348633 C 3.621981620788574 1.000001907348633 1.000001907348633 3.621981620788574 1.000001907348633 6.844831466674805 C 1.000001907348633 10.06768131256104 3.621981620788574 12.68966102600098 6.844831466674805 12.68966102600098 C 10.06768131256104 12.68966102600098 12.68966102600098 10.06768131256104 12.68966102600098 6.844831466674805 C 12.68966102600098 3.621981620788574 10.06768131256104 1.000001907348633 6.844831466674805 1.000001907348633 M 6.844831466674805 1.9073486328125e-06 C 10.62513160705566 1.9073486328125e-06 13.68966102600098 3.064531326293945 13.68966102600098 6.844831466674805 C 13.68966102600098 10.62513160705566 10.62513160705566 13.68966102600098 6.844831466674805 13.68966102600098 C 3.064531326293945 13.68966102600098 1.9073486328125e-06 10.62513160705566 1.9073486328125e-06 6.844831466674805 C 1.9073486328125e-06 3.064531326293945 3.064531326293945 1.9073486328125e-06 6.844831466674805 1.9073486328125e-06 Z"
                        stroke="none"
                        fill="#fff"
                      />
                    </g>
                    <text
                      id="_"
                      data-name="+"
                      transform="translate(6.845 12.059)"
                      fill="#fff"
                      fontSize="11"
                      fontFamily="Assistant-SemiBold, Assistant"
                      fontWeight="600"
                    >
                      <tspan
                        x="2.783"
                        y="0"
                      >
                        +
                      </tspan>
                    </text>
                  </g>
                </g>
              </svg>
            </span>
            <p className="text1">אירוע חדש</p>


          </NavLink>
          </div>

        </button>
      </div>

    </>
  );
}
