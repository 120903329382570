import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { topbarBgGreen } from "../Assets/images";
import { API_DOMAIN } from "../Libs/config";

function ConfirmReceiptGift({ setCurrWishlist, setMyEventsActiveTab }) {
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [providerDetails, setProviderDetails] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [receptionNotes, setReceptionNotes] = useState("");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get("orderId");

    if (!orderId) {
      setError("Order ID is missing in the URL.");
      setLoading(false);
      return;
    }

    // Fetch order data
    axios
      .get(`${API_DOMAIN}/api/ProductCategory/GetOrderById`, {
        params: { orderId },
      })
      .then(async (response) => {
        const { result, order, orderItemsObjectList } = response.data;

        if (!result) {
          setError("Failed to fetch order data.");
          setLoading(false);
          return;
        }

        setOrderData(order);

        // Fetch product details for all items in the order
        const productPromises = orderItemsObjectList.map((item) =>
          axios
            .get(`${API_DOMAIN}/api/ProductCategory/GetProductById`, {
              params: { productId: item.productsId },
            })
            .then((res) => res.data)
            .catch((err) => {
              console.error(
                `Failed to fetch product details for productId ${item.productsId}:`,
                err
              );
              return null;
            })
        );

        const products = await Promise.all(productPromises);
        const validProducts = products.filter((product) => product !== null);

        setProductDetails(validProducts);

        if (validProducts.length > 0) {
          const providerId = validProducts[0].product.providerId;
          return axios
            .get(`${API_DOMAIN}/api/Authenticate/getUserById`, {
              params: { userId: providerId },
            })
            .then((providerResponse) => {
              setProviderDetails(providerResponse.data);
              setLoading(false);
            })
            .catch((err) => {
              console.error("Failed to fetch provider details:", err);
              setError("Failed to fetch provider details.");
              setLoading(false);
            });
        } else {
          setError("No valid products found.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching order data:", error);
        setError("Failed to fetch order data. Please try again.");
        setLoading(false);
      });
  }, [location.search]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!orderData) {
      alert("Order data is missing.");
      return;
    }

    const payload = {
      id: orderData.id,
      ReceptionNotes: receptionNotes,
      ConfirmationReceipt: selectedOption === "received",
    };

    axios
      .post(`${API_DOMAIN}/api/ProductCategory/UpdateOrderRecieved`, payload)
      .then((response) => {
        if (response.data.result) {
          alert("עודכן בהלחה");
        } else {
          alert("Failed to update the order.");
        }
      })
      .catch((error) => {
        console.error("Error updating the order:", error);
        alert("An error occurred. Please try again.");
      });
  };

  return (
    <main className="contact-us">
      <section className="contact-us__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img src={topbarBgGreen} alt="topbar" />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">אישור הזמנה</h1>
          </div>
        </div>
      </section>
      <div className="contact-us__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>צור קשר</p>
            </li>
          </ul>
        </div>
      </div>
      <section className="contact-us__main main-contact-us">
        <div className="main-contact-us__container">
          <h2 className="main-contact-us__title">האם קבלת את המתנה שלך?</h2>
          <div className="my-purchase-single">
            <div
              style={{ marginBottom: "1.5rem" }}
              className="my-purchase-single__content"
            >
              <div className="my-purchase-single__image-ibg">
                <img src={productDetails[0]?.product?.images} alt="Product" />
              </div>
              <div className="my-purchase-single__text-content">
                <h2 className="my-purchase-single__name">
                  {productDetails[0]?.product?.name}
                </h2>
                <p className="my-purchase-single__price">
                  ₪{productDetails[0]?.product?.price}
                </p>
              </div>
            </div>
          </div>
          <div style={{fontSize: '1.001rem', fontWeight:'bold' }} className="main-contact-us__form-block">
 
          <form onSubmit={handleSubmit} className="main-contact-us__form">
            <div className="contact-us-form__body">
              <div className="contact-us-form__col">
                <label className="contact-us-form__input-wrapper">
                  <span style={{ marginBottom: "10px" }}>
                    בחר אפשרות<span>*</span>
                  </span>
                  <div
                    style={{ marginTop: "1rem" }}
                    className="contact-us-form__radio-group"
                  >
                    <label s className="contact-us-form__radio-label">
                      <input
                        style={{marginLeft: '0.2rem'}}
                        type="radio"
                        name="received"
                        value="received"
                        checked={selectedOption === "received"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        required
                      />
                      קבלתי
                    </label>
                    <label style={{marginRight: '0.5rem'}} className="contact-us-form__radio-label">
                      <input
                                              style={{marginLeft: '0.2rem'}}

                        type="radio"
                        name="received"
                        value="notReceived"
                        checked={selectedOption === "notReceived"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        required
                      />
                      לא קבלתי
                    </label>
                  </div>
                </label>
                {selectedOption === "notReceived" && (
                  <div className="contact-us-form__message">
                    <span>אנא צור קשר עם הספק</span>
                    <div style={{ marginTop: "0.3rem" }}>
                      <span>
                        {providerDetails?.firstName} {providerDetails?.lastName}
                      </span>
                      <br />
                      <span>{providerDetails?.phoneNumber}</span>
                      <br />
                      <span>{providerDetails?.email}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="contact-us-form__col">
                <label
                  htmlFor="contact-us-form__message"
                  className="contact-us-form__input-wrapper contact-us-form__input-wrapper_txta"
                >
                  <span className="input-title">הערות</span>
                  <textarea
                    autoComplete="off"
                    name="message"
                    id="contact-us-form__message"
                    className="input"
                    value={receptionNotes}
                    onChange={(e) => setReceptionNotes(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className="contact-us-form__btn-container btn-container_center">
              <button type="submit" className="btn">
                שלח/י
              </button>
            </div>
          </form>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ConfirmReceiptGift;
